import { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function AssetTable(props) {

    const [asset_data, set_asset_data] = useState([])
    const [loading, set_loading] = useState(true)
    const navigate = useNavigate();

    useEffect(()=>{
        axios.get("/api/units/")
            .then((res) => {
                let data = res.data;
                set_asset_data(data)
                set_loading(false)
            }    
        )
    }, [])

    let assets = []
    
    if(loading) {
        assets.push(<tr key='loading-row'><td colSpan='3' key="loading">loading...</td></tr>)
    }

    for(let item of asset_data) {
        let nav_link = `/analytics/${item.id}`
        assets.push(
            <tr key={item.id} className="bg-gray-100 border-b dark:bg-gray-700 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer" onClick={()=> navigate(nav_link)}>
                <td className='px-1 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>{item.unit_model}</td>
                <td className='px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>{item.unit_desc}</td>
                <td className='px-2 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>{item.status}</td>
            </tr>
        )
    }

    return(
        <div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead>
                    <tr>
                        <th scope="col" className="px-1 py-3">
                            Unit Model
                        </th>
                        <th scope="col" className="px-4 py-3">
                            Description
                        </th>
                        <th scope="col" className="px-2 py-3">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {assets}
                </tbody>
            </table>
        </div>
    )
}