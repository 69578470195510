import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import SideBar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import { default as MyHeader } from "../components/Header";
import AssetTable from "../components/assets-table/AssetTable";

export function MyAssetsView(props) {
    const navigate = useNavigate();

    async function logoutClicked() {
        await signOut();
        navigate("/auth");
    };

    return(
        <div className="h-screen flex">
            <SideBar logout={logoutClicked}/>
            <div className="h-screen w-full p-6">
                <Topbar />
                <div className="flex justify-between items-center" >
                    <MyHeader title="My Assets" subtitle="View your rented equipment"/>
                </div>
                <div className='h-[calc(100vh-190px)] rounded-md flex-auto overflow-auto bg-gray-100 p-5 dark:bg-gray-700'>
                    <AssetTable />
                </div>
            </div>
        </div>
    );
};
