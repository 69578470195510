import { AssetsMapView } from "./AssetsMapView";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

export default function AssetsMap() {
    const sessionContext = useSessionContext();

    if (sessionContext.loading === true) {
        return null;
    }

    return (
        <div className="w-screen h-screen bg-opacity-80 bg-gray-100 dark:bg-gray-900" id="home-container">
            <AssetsMapView userId={sessionContext.userId} />
        </div>
    );
}
