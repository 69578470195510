import { useState, useEffect } from "react";
import Topbar from "../components/topbar/Topbar";
import Header from "../components/Header";
import axios from "axios";
import DredgeAnalyticsView from "./DredgeAnalyticsView";
import PumpAnalyticsView from "./PumpAnalyticsView";

export default function UnitAnalyticsView(props) {
    const [unitData, setUnitData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        axios.get(`/api/units/${props.asset_id}/`)
            .then((res)=>{
                let data = res.data
                setUnitData(data)
                setLoading(false)
            })
    })

    if(loading) {
        return(
            <div className="h-screen w-screen p-6">
                <Topbar />
                <div className="flex justify-between items-center" >
                    <Header title={"Analytics For unit " + props.asset_id} subtitle="Live data graphs and calculated metrics"/>
                </div>
                Loading...
            </div>
        )
    }
    else {
        if(unitData.unit_type === "dredge") {
            return (
                <DredgeAnalyticsView asset_id={props.asset_id}/>
            )
        }
        else if(unitData.unit_type === "pump") {
            return (
                <PumpAnalyticsView asset_id={props.asset_id}/>
            )
        }
    }


}