import { LuSearch, LuSettings } from "react-icons/lu"

const Topbar = () => {
    return (
        <div className="flex justify-between">
            <div className="flex">
                <input type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search..." required />
                <button type="submit" className="p-2.5">
                    <LuSearch className="text-gray-800 dark:text-white"/>
                </button>
            </div>

            <div className="flex p-2.5">
                <button> 
                    <LuSettings className="text-gray-800 dark:text-white"/>
                </button>
            </div>
        </div>
    );
};

export default Topbar;
