import { Sidebar } from 'flowbite-react'
import { LuLogOut, LuTableProperties , LuLineChart, LuMap, LuLayoutDashboard} from 'react-icons/lu'
import { useState } from 'react'

export default function SideBar(props) {
    let active_items = {
        '/home': false,
        '/assetmap': false,
        '/analytics': false,
        '/myassets': false
    }

    let initial_img_src = "/assets/img/cpp-black-sub.svg"
    let path = window.location.pathname
    active_items[path] = true

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        // dark mode
        initial_img_src = "/assets/img/cpp-white-sub.svg"
    }
    
    let [img_src, set_img_src] = useState(initial_img_src)

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        const newColorScheme = event.matches ? "dark" : "light";

        if(newColorScheme === "dark") {
            set_img_src("/assets/img/cpp-white-sub.svg")
        }
        else {
            set_img_src("/assets/img/cpp-black-sub.svg")
        }
    });
    
    return(
       <Sidebar>
            <img className='object-fill mb-10 mt-2' src={img_src} alt="CPP LOGO" />
            <Sidebar.Items>
                <Sidebar.ItemGroup>
                    <Sidebar.Item href="/home" icon={LuLayoutDashboard} active={active_items['/home']}>
                        Dashboard
                    </Sidebar.Item>
                </Sidebar.ItemGroup>
                <Sidebar.ItemGroup>
                    <Sidebar.Item href="/assetmap" icon={LuMap} active={active_items['/assetmap']}>
                        Asset Map
                    </Sidebar.Item>
                </Sidebar.ItemGroup>
                <Sidebar.ItemGroup>
                    <Sidebar.Item href="/analytics" icon={LuLineChart} active={active_items['/analytics']}>
                        Analytics
                    </Sidebar.Item>
                </Sidebar.ItemGroup>
                <Sidebar.ItemGroup>
                    <Sidebar.Item href="/myassets" icon={LuTableProperties} active={active_items['/myassets']}>
                        My Assets
                    </Sidebar.Item>
                </Sidebar.ItemGroup>
                <Sidebar.ItemGroup onClick={props.logout}>
                    <Sidebar.Item style={{cursor: 'pointer'}} icon={LuLogOut}>
                        Logout
                    </Sidebar.Item>
                </Sidebar.ItemGroup>
            </Sidebar.Items>
       </Sidebar>
    )
}