import { useState, useEffect } from "react";
import Header from "../components/Header";
import { getMQTTBrokerDomain } from "../config";
import mqtt from 'mqtt';
import { LineChart, Line, XAxis, YAxis, Label, Tooltip } from 'recharts';

export default function PumpAnalyticsView(props) {
    const [unit_mqtt_update, set_unit_mqtt_update] = useState({
        "state": "No connection",
        "flow_rate": 0,
        "adc_sensor1_value": 0,
        "adc_sensor2_value": 0,
        "frequency": 0,
        "current": 0,
        "anomaly_score": 0,
    })
    const [connection_status, set_connection_status] = useState("disconnected")
    const [client, setClient] = useState(null);

    const mqttConnect = (host, options) => {
        set_connection_status('Connecting');
        
        try{
            setClient(mqtt.connect(host, options));
        }  
        catch(e) {
            console.log(e)
        } 
    };
    
    const [flow_rate_data, set_flow_rate_data] = useState([])
    const [anomaly_score_data, set_anomaly_score_data] = useState([])
    const [adc_sensor1_data, set_adc_sensor1_data] = useState([])
    const [adc_sensor2_data, set_adc_sensor2_data] = useState([])
    const [frequency_data, set_frequency_data] = useState([])
    const [current_data, set_current_data] = useState([])

    useEffect(()=>{

        const { brokerAddress, brokerUser, brokerPassword } = getMQTTBrokerDomain();
        const options = {
            username:brokerUser,
            password: brokerPassword,
        };


        mqttConnect(`wss://${brokerAddress}`, options)
  
    }, [props.asset_id])

    useEffect(()=>{
        if (client && connection_status==="Connecting") {
            client.on('connect', () => {
                set_connection_status('Connected');
                console.log(connection_status)
                client.subscribe(`unit/${props.asset_id}`);
            });
            client.on('error', (err) => {
                console.error('Connection error: ', err);
                console.log("error")
                if(connection_status === "connected") {
                    client.end();
                }
                set_connection_status('Error');
            });
            client.on('reconnect', () => {
                set_connection_status('Reconnecting');
            });
            client.on('message', (topic, message) => {
                let decoder = new TextDecoder('utf-8')
                let json_message = JSON.parse(decoder.decode(message))
                console.log(json_message)
 
                //from https://stackoverflow.com/questions/847185/convert-a-unix-timestamp-to-time-in-javascript
                let new_formatted_time = new Date(json_message.timestamp*1000)
                // Hours part from the timestamp
                let hours = new_formatted_time.getHours();
                // Minutes part from the timestamp
                let minutes = "0" + new_formatted_time.getMinutes();
                // Seconds part from the timestamp
                let seconds = "0" + new_formatted_time.getSeconds();
                let time = hours + ':' + minutes.slice(-2) + ':' + seconds.slice(-2)

                adc_sensor1_data.push({time:time, pressure: json_message.adc_sensor1_value})
                adc_sensor2_data.push({time:time, pressure: json_message.adc_sensor2_value})
                frequency_data.push({time:time, frequency: json_message.frequency})
                current_data.push({time:time, current: json_message.current})

                if(adc_sensor1_data.length > 50) {
                    adc_sensor1_data.shift()
                }
                if(adc_sensor2_data.length > 50) {
                    adc_sensor2_data.shift()
                }
                if(frequency_data.length > 50) {
                    frequency_data.shift()
                }
                if(current_data.length > 50) {
                    current_data.shift()
                }

                set_adc_sensor1_data([...adc_sensor1_data])
                set_adc_sensor2_data([...adc_sensor2_data])
                set_frequency_data([...frequency_data])
                set_current_data([...current_data])

                if (json_message.flow_rate != null || json_message.anomaly_score != null){
                    flow_rate_data.push({time:time, flow: json_message.flow_rate})
                    anomaly_score_data.push({time:time, mse: json_message.anomaly_score, threshold: 0.03})

                    if(flow_rate_data.length > 50) {
                        flow_rate_data.shift()
                    }    
                    if(anomaly_score_data.length > 50) {
                        anomaly_score_data.shift()
                    }

                    set_flow_rate_data([...flow_rate_data])
                    set_anomaly_score_data([...anomaly_score_data])
                } else {
                    json_message.anomaly_score = 0
                    json_message.flow_rate = 0
                    set_flow_rate_data([])
                    set_anomaly_score_data([])
                }
                set_unit_mqtt_update(json_message)
            });
        }

    }, [client, props.asset_id, connection_status, frequency_data, current_data, anomaly_score_data,
        flow_rate_data, adc_sensor1_data, adc_sensor2_data, unit_mqtt_update])
    
    return(
        <>
            <div className="h-screen w-screen p-6">
                <div className="flex justify-between items-center" >
                    <Header title={"Analytics For unit " + props.asset_id} subtitle="Live data graphs and calculated metrics"/>
                </div>
                <div className="flex justify-between items-center text-gray-800 dark:text-white">
                    <h2>Status: {unit_mqtt_update.state}</h2>
                </div>

                <div className="grid grid-cols-3 grid-rows-2 gap-2 h-[calc(100vh-200px)] overflow-y-auto">
 
                    {/* ROW 1 */}
                    <div className="bg-gray-100 p-5 rounded-md min-h-[370px] max-h-[500px] min-w-[400px] dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Depth Pressure: <span style={{ color: '#8884d8', fontWeight: 'bold' }}>{parseFloat(unit_mqtt_update.adc_sensor1_value.toFixed(3))} psi</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={275} data={adc_sensor1_data}>
                                <Line dataKey="pressure" stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey="pressure" domain={[0, 'auto']}>
                                    <Label
                                        value="Pressure (psi)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                    </div>
 
                    <div className="bg-gray-100 p-5 rounded-md min-h-[370px] max-h-[500px] min-w-[400px] dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Pump Speed: <span style={{ color: '#8BD984', fontWeight: 'bold' }}>{parseFloat(unit_mqtt_update.frequency.toFixed(3))} Hz</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={275} data={frequency_data}>
                                <Line dataKey="frequency" stroke="#8BD984" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey="frequency" domain={[0, 'auto']}>
                                    <Label
                                        value="Frequency (Hz)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                    </div>

                    <div className="bg-gray-100 p-5 rounded-md min-h-[370px] max-h-[500px] min-w-[400px] dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Anomaly Score: <span style={{ color: '#D98484', fontWeight: 'bold' }}>{parseFloat(unit_mqtt_update.anomaly_score.toFixed(3))}</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here  */}
                            <LineChart width={525} height={275} data={anomaly_score_data}>
                                <Line dataKey="mse" stroke="#D98484" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                <Line dataKey="threshold" stroke="#FF0000" type="monotone" isAnimationActive={false} dot={false} strokeWidth={2} strokeDasharray="7 4" />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey="mse" domain={[0, 'auto']}>
                                    <Label
                                        value="Mean Squared Error (MSE)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                    </div>

                    {/* ROW 2 */}
                    <div className="bg-gray-100 p-5 rounded-md min-h-[370px] max-h-[500px] min-w-[400px] dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Discharge Pressure: <span style={{ color: '#8884d8', fontWeight: 'bold' }}>{parseFloat(unit_mqtt_update.adc_sensor2_value.toFixed(3))} psi</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={275} data={adc_sensor2_data}>
                                <Line dataKey="pressure" stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey="pressure" domain={[0, 'auto']}>
                                    <Label
                                        value="Pressure (psi)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                    </div>

                    <div className="bg-gray-100 p-5 rounded-md min-h-[370px] max-h-[500px] min-w-[400px] dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Pump Current: <span style={{ color: '#8BD984', fontWeight: 'bold' }}>{parseFloat(unit_mqtt_update.current.toFixed(3))} amps</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here  */}
                            <LineChart width={525} height={275} data={current_data}>
                                <Line dataKey="current" stroke="#8BD984" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey="current" domain={[0, 'auto']}>
                                    <Label
                                        value="Amperage (amps)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                    </div>

                    <div className="bg-gray-100 p-5 rounded-md min-h-[370px] max-h-[500px] min-w-[400px] dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Calculated Flow Rate: <span style={{ color: '#D98484', fontWeight: 'bold' }}>{parseFloat(unit_mqtt_update.flow_rate.toFixed(3))} l/s</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here  */}
                            <LineChart width={525} height={275} data={flow_rate_data}>
                                <Line dataKey="flow" stroke="#D98484" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey="flow" domain={[0, 'auto']}>
                                    <Label
                                        value="Flow Rate (m/s)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                    </div>
 
                </div>
            </div>
        </>
    )
}
