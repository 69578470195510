import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import styles from "./Map.module.css"
import L from "leaflet";
import iconPath from './map-marker.svg';

const markerIcon = new L.Icon({
    iconUrl: iconPath,
    iconSize: [38, 38]
});

const createClusterCustomIcon = function (cluster) {
    return new L.divIcon({
        html: `<div>${cluster.getChildCount()}</div>`,
        className: styles.clusterIcon,
        iconSize: L.point(33, 33, true)
    });
};

// Fake data
// TODO: API call for real data
const markers = [
    {
        key: 1,
        geocode: [53.709258493445894, -113.18830012085442],
        popUp: "CPP Fort Sask Site"
    },
    {
        key: 2,
        geocode: [53.5584218523586, -112.97532574304259],
        popUp: "CPP Ardrossan Site"
    },
    {
        key: 3,
        geocode: [53.95451052141253, -113.0765839754763],
        popUp: "Redwater Site"
    },
    {
        key: 4,
        geocode: [51.001264562673875, -113.90765637008965],
        popUp: "Calgary Site"
    },
    {
        key: 5,
        geocode: [47.563258639638306, -81.94811814668584],
        popUp: "Cote Gold Site"
    },
  ];

const defaultCenter = [53.546, -113.494]; // Center on Edmonton
const defaultZoom = 6;

export default function Map() {
    return (
        <MapContainer className={styles.leafletContainer} center={defaultCenter} zoom={defaultZoom} minZoom={4} maxZoom={15}>
        <TileLayer
            attribution="Google Maps"
            url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        <MarkerClusterGroup
            onClick={(e) => console.log('onClick', e)}
            iconCreateFunction={createClusterCustomIcon}
            spiderfyOnMaxZoom={true}
            showCoverageOnHover={false}
        >
            {markers.map((marker) => (
            <Marker key={marker.key} position={marker.geocode} icon={markerIcon}>
                <Popup>{marker.popUp}</Popup>
            </Marker>
            ))}
        </MarkerClusterGroup>
    </MapContainer>
    );
};
