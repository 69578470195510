import { MyAssetsView } from "./MyAssetsView";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import {useParams} from "react-router-dom";

export default function MyAssets() {
    const sessionContext = useSessionContext();
    const {asset_id} = useParams();

    if (sessionContext.loading === true) {
        return null;
    }
    
    return (
        <div className="w-screen h-screen bg-opacity-80 bg-gray-100 dark:bg-gray-900" id="home-container">
            <MyAssetsView userId={sessionContext.userId} asset_id={asset_id}/>
        </div>
    )
}
