import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import { EmailVerificationPreBuiltUI } from "supertokens-auth-react/recipe/emailverification/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import CompanyDropdown from './components/company-dropdown/CompanyDropdown';


export function getApiDomain() {
    const apiUrl = process.env.REACT_APP_API_URL || "";
    return apiUrl;
}

export function getWebsiteDomain() {
    const websiteUrl = process.env.REACT_APP_WEBSITE_URL || "";
    return websiteUrl;
}

export function getMQTTBrokerDomain() {
    const brokerAddress = process.env.REACT_APP_BROKER_ADDRESS;
    const brokerUser = process.env.REACT_APP_BROKER_USER;
    const brokerPassword = process.env.REACT_APP_BROKER_PASSWORD;
    return { brokerAddress, brokerUser, brokerPassword };
}

export const SuperTokensConfig = {
    appInfo: {
        appName: "MightyDashboard",
        apiDomain: getApiDomain(),
        websiteDomain: getWebsiteDomain()
    },
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [EmailPassword.init({
        style: `

            [data-supertokens~=container] {
                background-color: #E8E8E8;
                --palette-primary: #4B5593;
                --palette-primaryBorder: #4B5593;
            }

            [data-supertokens~=button] {
                background-color: #4B5593;
                border: 0px;
                width: 30%;
                margin: 0 auto;
            }

            @media (max-width: 440px) {
                [data-supertokens~=button] {
                    width: 90%;
                }
            }
        `,
        signInAndUpFeature: {
            signUpForm: {

                formFields: [
                    {
                        id: "first_name",
                        label: "First Name",
                        optional: false
                    },
                    {
                        id: "last_name",
                        label: "Last Name",
                        optional: false
                    },
                    {
                        id: "company",
                        label: "Company",
                        inputComponent: ({ onChange }) => {
                            return (
                                <CompanyDropdown onSelect={onChange} />
                            )},
                        optional: true,
                    }
                ]
            }
        }
    }
    ), Session.init(), EmailVerification.init({mode: "REQUIRED"})],
};

export const recipeDetails = {
    docsLink: "https://supertokens.com/docs/emailpassword/introduction",
};

export const PreBuiltUIList = [EmailPasswordPreBuiltUI, EmailVerificationPreBuiltUI];

export const ComponentWrapper = (props: { children: JSX.Element }): JSX.Element => {
    return props.children;
};
