import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import SideBar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import Header from "../components/Header";
import Map from "../components/map/Map";

export function AssetsMapView(props) {
    const navigate = useNavigate();
    
    async function logoutClicked() {
        await signOut();
        navigate("/auth");
    }

    return(
        <div className="h-screen flex">
            <SideBar logout={logoutClicked}/>
            <div className="h-screen w-full p-6">
                <Topbar />
                <div className="flex justify-between items-center" >
                    <Header title="Asset Map" subtitle="Geographical distribution of assets"/>
                </div>
                <Map />
            </div>
        </div>
    )
}