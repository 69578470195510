import { useState, useEffect } from "react";
import Header from "../components/Header";
import { getMQTTBrokerDomain } from "../config";
import mqtt from 'mqtt';
import { LineChart, Line, XAxis, YAxis, Label, Tooltip} from 'recharts';


export default function DredgeAnalyticsView(props) {
    
    const [unit_mqtt_update, set_unit_mqtt_update] = useState({
        winch_update: [{status: {output_current: 0, output_power: 0}}, {status: {output_current: 0, output_power: 0}}, {status: {output_current: 0, output_power: 0}}, {status: {output_current: 0, output_power: 0}}],
        pump_update: {
            mode: "No Signal",
            position: {latitude: 0, longitude: 0},
            pump_status: {
                output_current: 0,
                output_frequency: 0,
                output_power: 0
            }
        }
    })

    const [connection_status, set_connection_status] = useState("disconnected")
    // const [mqtt_client, set_mqtt_client] = useState(mqtt.connect(`ws://${brokerAddress}:${brokerPort}`))
    const [client, setClient] = useState(null);

    const mqttConnect = (host, options) => {
        set_connection_status('Connecting');
        
        try{
            setClient(mqtt.connect(host, options));
        }  
        catch(e) {
            console.log(e)
        } 
    };
    
    const [winchA_amps, set_winchA_amps] = useState([])
    const [winchB_amps, set_winchB_amps] = useState([])
    const [winchC_amps, set_winchC_amps] = useState([])
    const [winchD_amps, set_winchD_amps] = useState([])
    const [winchA_power, set_winchA_power] = useState([])
    const [winchB_power, set_winchB_power] = useState([])
    const [winchC_power, set_winchC_power] = useState([])
    const [winchD_power, set_winchD_power] = useState([])
    const [pump_amps, set_pump_amps] = useState([])
    const [pump_power, set_pump_power] = useState([])
    const [pump_hz, set_pump_hz] = useState([])
    const [discharge_psi, set_discharge_psi] = useState([])

    useEffect(()=>{
        const { brokerAddress, brokerUser, brokerPassword } = getMQTTBrokerDomain();
        const options = {
            username: brokerUser,
            password: brokerPassword,
        };

        console.log("connect call")
        mqttConnect(`wss://${brokerAddress}`, options)
        
    }, [props.asset_id])

    useEffect(()=>{
        if (client && connection_status==="Connecting") {
            client.on('connect', () => {
                set_connection_status('Connected');
                console.log(connection_status)
                client.subscribe(`mightydredge/#`);
            });
            client.on('error', (err) => {
                console.error('Connection error: ', err);
                console.log("error")
                if(connection_status === "connected") {
                    client.end();
                }
                set_connection_status('Error');
            });
            client.on('reconnect', () => {
                set_connection_status('Reconnecting');
                console.log(connection_status)
            });
            client.on('message', (topic, message) => {
                let decoder = new TextDecoder('utf-8')
                let json_message = JSON.parse(decoder.decode(message))
                // console.log(json_message)
 
                //from https://stackoverflow.com/questions/847185/convert-a-unix-timestamp-to-time-in-javascript
                let new_formatted_time = new Date(json_message.timestamp)
                // Hours part from the timestamp
                let hours = new_formatted_time.getHours();
                // Minutes part from the timestamp
                let minutes = "0" + new_formatted_time.getMinutes();
                // Seconds part from the timestamp
                let seconds = "0" + new_formatted_time.getSeconds();
                let time = hours + ':' + minutes.slice(-2) + ':' + seconds.slice(-2)

                winchA_amps.push({time: time, amps: json_message.winch_update[0].status.output_current})
                winchA_power.push({time: time, power: json_message.winch_update[0].status.output_power})

                winchB_amps.push({time: time, amps: json_message.winch_update[1].status.output_current})
                winchB_power.push({time: time, power: json_message.winch_update[1].status.output_power})

                winchC_amps.push({time: time, amps: json_message.winch_update[2].status.output_current})
                winchC_power.push({time: time, power: json_message.winch_update[2].status.output_power})

                winchD_amps.push({time: time, amps: json_message.winch_update[3].status.output_current})
                winchD_power.push({time: time, power: json_message.winch_update[3].status.output_power})

                pump_amps.push({time: time, amps: json_message.pump_update.pump_status.output_current})
                pump_power.push({time: time, power: json_message.pump_update.pump_status.output_power})

                pump_hz.push({time: time, frequency: json_message.pump_update.pump_status.output_frequency/100})
                
                discharge_psi.push({time: time, pressure: json_message.discharge_pressure})

                if(winchA_amps.length > 50) {
                    winchA_amps.shift()
                }
                if(winchA_power.length > 50) {
                    winchA_power.shift()
                }

                if(winchB_amps.length > 50) {
                    winchB_amps.shift()
                }
                if(winchB_power.length > 50) {
                    winchB_power.shift()
                }

                if(winchC_amps.length > 50) {
                    winchC_amps.shift()
                }
                if(winchC_power.length > 50) {
                    winchC_power.shift()
                }

                if(winchD_amps.length > 50) {
                    winchD_amps.shift()
                }
                if(winchD_power.length > 50) {
                    winchD_power.shift()
                }

                if(pump_amps.length > 50) {
                    pump_amps.shift()
                }
                if(pump_power.length > 50) {
                    pump_power.shift()
                }

                if(pump_hz.length > 50) {
                    pump_hz.shift()
                }

                if(discharge_psi.length > 50) {
                    discharge_psi.shift()
                }

                set_winchA_amps([...winchA_amps])
                set_winchA_power([...winchA_power])

                set_winchB_amps([...winchB_amps])
                set_winchB_power([...winchB_power])

                set_winchC_amps([...winchC_amps])
                set_winchC_power([...winchC_power])

                set_winchD_amps([...winchD_amps])
                set_winchD_power([...winchD_power])

                set_pump_amps([...pump_amps])
                set_pump_power([...pump_power])
                set_pump_hz([...pump_hz])

                set_discharge_psi([...discharge_psi])

                set_unit_mqtt_update(json_message)
            });
        }

    }, [client, props.asset_id, connection_status, winchA_amps, winchA_power, winchB_amps, winchB_power, winchC_amps, winchC_power, winchD_amps, winchD_power,
            pump_hz, pump_amps, pump_power, unit_mqtt_update, discharge_psi
        ])//[client, props.asset_id, connection_status, frequency_data, current_data, anomaly_score_data,
        //flow_rate_data, adc_sensor1_data, adc_sensor2_data, unit_mqtt_update])
    
    return (
        <>
            <div className="h-screen w-screen p-6">
                <div className="flex justify-between items-center" >
                    <Header title={"Analytics For unit " + props.asset_id} subtitle="Live data graphs and calculated metrics"/>
                </div>
                <div className="inline-flex justify-between items-center text-gray-800 dark:text-white">
                    <h2>Status: {unit_mqtt_update.pump_update.mode}</h2>
                    <h2 style={{marginLeft: "20px"}}>Latitude: {unit_mqtt_update.pump_update.position.latitude.toFixed(6)}</h2>
                    <h2 style={{marginLeft: "20px"}}>Longitude: {unit_mqtt_update.pump_update.position.longitude.toFixed(6)}</h2>
                </div>

                <div className="grid grid-cols-3 grid-rows-2 gap-2 h-[calv(100vh-200px)] overflow-auto">
                    {/* ROW 1 */}
                    <>
                    <div className="bg-gray-100 p-5 rounded-md min-h-[370px] max-h-[500px] min-w-[400px] dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Winch A Current: <span style={{ color: '#8884d8', fontWeight: 'bold' }}> {unit_mqtt_update.winch_update[0].status.output_current} A</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={125} data={winchA_amps}>
                                <Line dataKey={"amps"} stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey={"amps"} domain={[0, 'auto']}>
                                    <Label
                                        value="Winch A current (A)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Winch A Power: <span style={{ color: '#8884d8', fontWeight: 'bold' }}> {unit_mqtt_update.winch_update[0].status.output_power} W</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={125} data={winchA_power}>
                                <Line dataKey={"power"} stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey={"power"} domain={[0, 'auto']}>
                                    <Label
                                        value="Winch D power (W)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                    </div>

                    <div className="bg-gray-100 p-5 rounded-md min-h-[370px] max-h-[500px] min-w-[400px] dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Winch B Current: <span style={{ color: '#8884d8', fontWeight: 'bold' }}> {unit_mqtt_update.winch_update[1].status.output_current} A</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={125} data={winchB_amps}>
                                <Line dataKey={"amps"} stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey={"amps"} domain={[0, 'auto']}>
                                    <Label
                                        value="Winch B current (A)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Winch B Power: <span style={{ color: '#8884d8', fontWeight: 'bold' }}> {unit_mqtt_update.winch_update[1].status.output_power} W</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={125} data={winchB_power}>
                                <Line dataKey={"power"} stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey={"power"} domain={[0, 'auto']}>
                                    <Label
                                        value="Winch B power (W)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                    </div>

                    <div className="bg-gray-100 p-5 rounded-md min-h-[370px] max-h-[500px] min-w-[400px] dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Pump Current: <span style={{ color: '#8884d8', fontWeight: 'bold' }}> {unit_mqtt_update.pump_update.pump_status.output_current} A</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={125} data={pump_amps}>
                                <Line dataKey={"amps"} stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey={"amps"} domain={[0, 'auto']}>
                                    <Label
                                        value="Pump current (A)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Pump Power: <span style={{ color: '#8884d8', fontWeight: 'bold' }}> {unit_mqtt_update.pump_update.pump_status.output_power} W</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={125} data={pump_power}>
                                <Line dataKey={"power"} stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey={"power"} domain={[0, 'auto']}>
                                    <Label
                                        value="Pump Power (W)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                    </div>
                    </>
                    {/* ROW 2 */}
                    <>
                    <div className="bg-gray-100 p-5 rounded-md min-h-[370px] max-h-[500px] min-w-[400px] dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Winch C Current: <span style={{ color: '#8884d8', fontWeight: 'bold' }}> {unit_mqtt_update.winch_update[2].status.output_current} A</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={125} data={winchC_amps}>
                                <Line dataKey={"amps"} stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey={"amps"} domain={[0, 'auto']}>
                                    <Label
                                        value="Winch C current (A)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Winch C Power: <span style={{ color: '#8884d8', fontWeight: 'bold' }}> {unit_mqtt_update.winch_update[2].status.output_power} W</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={125} data={winchC_power}>
                                <Line dataKey={"power"} stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey={"power"} domain={[0, 'auto']}>
                                    <Label
                                        value="Winch C power (W)"
                                        dx={-20}
                                        angle={-90}
                                    />
                                </YAxis>
                            </LineChart>
                        </div>
                    </div>

                    <div className="bg-gray-100 p-5 rounded-md min-h-[370px] max-h-[500px] min-w-[400px] dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Winch D Current: <span style={{ color: '#8884d8', fontWeight: 'bold' }}> {unit_mqtt_update.winch_update[3].status.output_current} A</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={125} data={winchD_amps}>
                                <Line dataKey={"amps"} stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey={"amps"} domain={[0, 'auto']}>
                                    <Label
                                        value="Winch D current (A)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Winch D Power: <span style={{ color: '#8884d8', fontWeight: 'bold' }}> {unit_mqtt_update.winch_update[3].status.output_power} W</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={125} data={winchD_power}>
                                <Line dataKey={"power"} stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey={"power"} domain={[0, 'auto']}>
                                    <Label
                                        value="Winch D Power (W)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                    </div>

                    <div className="bg-gray-100 p-5 rounded-md min-h-[370px] max-h-[500px] min-w-[400px] dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Pump frequency: <span style={{ color: '#8884d8', fontWeight: 'bold' }}> {unit_mqtt_update.pump_update.pump_status.output_frequency/100} Hz</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={125} data={pump_hz}>
                                <Line dataKey={"frequency"} stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey={"frequency"} domain={[0, 'auto']}>
                                    <Label
                                        value="Pump frequency (Hz)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">
                            Discharge Pressure: <span style={{ color: '#8884d8', fontWeight: 'bold' }}> {unit_mqtt_update.discharge_pressure} Psi</span>
                        </div>
                        <div className="flex flex-col items-center mt-6">
                            {/* Component here /> */}
                            <LineChart width={525} height={125} data={discharge_psi}>
                                <Line dataKey={"pressure"} stroke="#8884d8" type="monotone" isAnimationActive={false} dot={false} strokeWidth={3} />
                                {/* <CartesianGrid stroke="#ccc" /> */}
                                <XAxis dataKey="time" domain={['dataMin', 'dataMin+50']}>
                                    <Label
                                        value="Time"
                                        dy={12}
                                    />
                                </XAxis>
                                <Tooltip />
                                <YAxis dataKey={"pressure"} domain={[0, 'auto']}>
                                    <Label
                                        value="Discharge pressure (Psi)"
                                        dx={-20}
                                        angle={-90}
                                    /> 
                                </YAxis>
                            </LineChart>
                        </div>
                    </div>
                    </>
                </div>

            </div>
        </>
    )

}