import "./App.css";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { Routes, BrowserRouter as Router, Route, Navigate } from "react-router-dom";
import Home from "./Home";
import { PreBuiltUIList, SuperTokensConfig, ComponentWrapper } from "./config";
import AssetMap from "./AssetsMap";
import Analytics from "./Analytics";
import MyAssets from "./MyAssets";


SuperTokens.init(SuperTokensConfig);

function App() {
    return (
        <SuperTokensWrapper>
            <ComponentWrapper>
                <div className="App app-container">
                    <Router>
                        <div className="fill">
                            <Routes>
                                {/* This shows the login UI on "/auth" route */}
                                {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"), PreBuiltUIList)}
                                <Route path="/" element={<Navigate to="/home" replace={true} />}/>
                                <Route
                                    path="/home"
                                    element={
                                        <SessionAuth>
                                            <Home />
                                        </SessionAuth>
                                    }
                                />
                                <Route
                                    path="/assetmap"
                                    element={
                                        <SessionAuth>
                                            <AssetMap />
                                        </SessionAuth>
                                    }
                                />
                                 <Route
                                    path="/analytics/:asset_id?"
                                    element={
                                        <SessionAuth>
                                            <Analytics />
                                        </SessionAuth>
                                    }
                                />
                                 <Route
                                    path="/myassets/:asset_id?"
                                    element={
                                        <SessionAuth>
                                            <MyAssets />
                                        </SessionAuth>
                                    }
                                />
                            </Routes>
                        </div>
                    </Router>
                </div>
            </ComponentWrapper>
        </SuperTokensWrapper>
    );
}

export default App;
