import { HomeView } from "./HomeView";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import './Home.css';

export default function Home() {
    const sessionContext = useSessionContext();

    if (sessionContext.loading === true) {
        return null;
    }

    return (
        <div className="w-screen h-screen bg-opacity-80 bg-gray-100 dark:bg-gray-900" id="home-container">
            <HomeView userId={sessionContext.userId} />
        </div>
    );
}
