const Header = ({ title, subtitle }) => {
    return (
        <div className='my-4' > 
            <div className='text-3xl font-bold uppercase text-gray-600 mb-1'> 
                {title} 
            </div>
            <div className='text-md font-medium text-cyan-600'>
                {subtitle} 
            </div>
        </div>
    );
}

export default Header;
