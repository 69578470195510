import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import SideBar from "../components/sidebar/Sidebar";
import UnitAnalyticsView from "./UnitAnalyticsView";
import AnalyticsOverview from "./AnalyticsOverview";

export function AnalyticsView(props) {
    const navigate = useNavigate();

    async function logoutClicked() {
        await signOut();
        navigate("/auth");
    }

    let main_display;
    if(props.asset_id) {
        main_display = <UnitAnalyticsView asset_id={props.asset_id}/>
    }
    else {
        main_display = <AnalyticsOverview/>
    }

    return(
        <div className="h-screen flex">
            <SideBar logout={logoutClicked}/>
            {main_display}
        </div>
    )
}