import Topbar from "../components/topbar/Topbar";
import Header from "../components/Header";

export default function AnalyticsOverview(props) {
    return(
        <div className="h-screen w-full p-6">
            <Topbar />
            <div className="flex justify-between items-center" >
                <Header title="Analytics" subtitle="Live data graphs and calculated metrics"/>
            </div>
        </div>
    )
}
