import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import SideBar from "../components/sidebar/Sidebar";
import Topbar from "../components/topbar/Topbar";
import Header from "../components/Header";
import Map from "../components/map/Map";
import AssetTable from "../components/assets-table/AssetTable";
import { LuMap } from "react-icons/lu";

export function HomeView(props) {
    const navigate = useNavigate();

    async function logoutClicked() {
        await signOut();
        navigate("/auth");
    }

    return(
        <div className="h-screen flex">
            <SideBar logout={logoutClicked}/>
            <div className="h-screen w-full p-6">
                <Topbar />
                <div className="flex justify-between items-center" >
                    <Header title="DASHBOARD" subtitle="Welcome to the dashboard"/>
                </div>

                <div className="grid grid-cols-5 grid-rows-4 gap-4 h-[calc(100vh-200px)] overflow-auto">

                    {/* ROW 1 */}
                    <div className="bg-gray-100 p-5 rounded-md  dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">Component 1 - UNIT RUNTIME</div> 
                        <div className="flex flex-col items-center mt-6"> 
                            {/* Component here  */}
                        </div>
                    </div>

                    <div className="bg-gray-100 p-5 rounded-md  dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">Component 2 - UNIT USAGE</div> 
                    </div>

                    <div className="col-span-3 row-span-2 bg-gray-100 p-5 rounded-md  dark:bg-gray-700">
                        <div className="flex justify-between">
                            <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">Asset Map</div> 
                            <LuMap className="text-2xl text-gray-800 dark:text-white"/>
                        </div>
                        <Map/>
                    </div>

                    {/* ROW 2 */}
                    <div className="bg-gray-100 p-5 rounded-md  dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">Component 4 - WEEKLY USAGE</div> 
                        <div className="flex flex-col items-center mt-6"> 
                            {/* Component here  */}
                        </div>
                    </div>

                    <div className="bg-gray-100 p-5 rounded-md  dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">Component 5 - MONTHLY USAGE</div> 
                        <div className="mt-[-20px]"> 
                            {/* Component here /> */}
                        </div> 
                    </div>

                    {/* ROW 3 & 4 */}
                    <div className="col-span-3 row-span-2 bg-gray-100 p-5 flex-1 rounded-md dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">UNIT TABLE</div>
                        <div className="overflow-y-auto h-[90%] rounded-md" >
                            <AssetTable />
                        </div>
                    </div>

                    <div className="col-span-2 row-span-2 bg-gray-100 p-5 rounded-md  dark:bg-gray-700">
                        <div className="text-lg font-semibold text-gray-800 mb-4 dark:text-white">Component 7 - UNIT PIE CHART</div> 
                        <div className="mt-[-20px]"> 
                            {/* Component here /> */}
                        </div> 
                    </div>

                </div>

            </div>
        </div>
    )
}